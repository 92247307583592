@import '@blueprintjs/core/lib/css/blueprint.css';
@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
    background-color: rgb(243, 243, 243);
    color: #4a4a4a;
}

a,
a:link,
a:hover {
    color: #0f61a9;
}

.mta-button {
    display: none;
}

.bp4-label {
    font-weight: bold;
}

.mta-select .bp4-form-group {
    display: flex;
    padding: 0.5rem 0;
}

.mta-select .bp4-label {
    width: 25%;
}

.mta-select .bp4-html-select select {
    background: #0f61a9;
    color: #ffffff;
    border: 2px solid #ffffff;
}

.mta-select .bp4-html-select .bp4-icon {
    color: #ffffff;
}

.bp4-slider-progress {
    background-color: rgba(255, 255, 255, 0.2);
}

.bp4-slider-progress.bp4-intent-primary {
    background-color: #f2a900;
}

.bp4-control.bp4-checkbox .bp4-control-indicator {
    box-shadow: inset 0 0 0 1px white;
}

.mta-table-bordered {
    border-width: 1px;
    --border-opacity: 1;
    border-color: #000;
    border-color: rgba(0, 0, 0, var(--border-opacity));
    border-style: solid;
}
.mta-table table {
    max-width: 100%;
    empty-cells: show;
    width: 100%;
    border-collapse: collapse;
    padding: 0;
    border-width: 0;
    box-sizing: border-box;
}
.mta-table tbody > tr > td,
.mta-table thead > tr > th,
.mta-table > thead > tr > th {
    min-width: 100px;
    border-width: 1px;
    border-style: solid;
    --border-opacity: 1;
    border-color: #000;
    border-color: rgba(0, 0, 0, var(--border-opacity));
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
    padding-right: 0.8rem;
    padding-left: 0.8rem;
    text-align: left;
}

@media screen and (max-width: 900px) {
    .mta-table-responsive {
        border-width: 0;
        width: 100%;
    }
    .mta-table-responsive table {
        border-width: 0;
        margin-bottom: 2rem;
    }
    .mta-table-responsive table thead {
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        width: 1px;
        overflow: hidden;
        position: absolute;
        border-width: 0;
        padding: 0;
    }
    .mta-table-responsive table,
    .mta-table-responsive tbody {
        width: 100%;
    }
    .mta-table-responsive tr {
        display: block;
        margin-bottom: 2rem;
        border-right-width: 1px;
        border-left-width: 1px;
        border-top-width: 1px;
        border-bottom-width: 2px;
        border-style: solid;
        --border-opacity: 1;
        border-color: #000;
        border-color: rgba(0, 0, 0, var(--border-opacity));
    }
    .mta-table-responsive table td::before {
        content: attr(data-title);
        float: left;
        font-weight: 700;
        margin-right: 5px;
    }
    .mta-table-responsive td {
        text-align: right;
        display: block;
        border-width: 0;
        border-bottom-width: 1px;
        border-style: solid;
        --border-opacity: 1;
        border-color: #000;
        border-color: rgba(0, 0, 0, var(--border-opacity));
        width: 100%;
    }
    .mta-table tbody > tr > td {
        border-right-width: 0;
        border-top-width: 0;
        border-left-width: 0;
    }
    .mta-button {
        border-radius: 4px;
        background-color: white;
        border: 2px solid #1060a9;
        color: #1060a9 !important;
        cursor: pointer;
        display: block;
        font-size: 16px;
        font-weight: 700;
        line-height: 19px;
        width: 100%;
        margin-top: 30px;
        min-width: 80px;
        padding: 8px 10px;
        text-align: center;
    }
}
